import React from "react"
import { Link } from "gatsby"
import logo from "./logo.png"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li>
                <a
                  href="https://www.beatport.com/artist/nikita-cano/592211"
                  title="Beatport"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beatport
                </a>
              </li>
              <li>
                <a
                  href="https://music.apple.com/es/artist/nikita-cano/490994047?l=en"
                  title="Apple Music"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apple Music
                </a>
              </li>
              <li>
                <a
                  href="https://open.spotify.com/artist/5es0ckctGcRgDY4n0F2erN"
                  title="Spotify"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spotify
                </a>
              </li>
              {/* New "🎧 CANO.fm" link */}
              <li className="nav-item-highlight">
              <a
                href="https://cano.fm"
                title="🎧 CANO.fm"
                target="_blank"
                rel="noopener noreferrer"
              >
                🎧 CANO.fm
              </a>
            </li>
              {/* New "🔥 CANO Drops" link */}
              <li className="nav-item-highlight">
                <a
                  href="https://canodrops.com"
                  title="🔥 CANO Drops"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  🔥 CANO Drops
                </a>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <img src={logo} alt="Nikita Cano" />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.youtube.com/channel/UCjh0rFZtP4LOuVfwzYirfdw"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              <a
                href="https://soundcloud.com/pheoru"
                title="Soundcloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                Soundcloud
              </a>
              <a
                href="https://mixcloud.com/pheoru"
                title="Mixcloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mixcloud
              </a>
              <a
                href="https://promodj.com/pheoru"
                title="PromoDJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                PromoDJ
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>Nikita Cano</Link> 🌈 &mdash;
        Let's get in touch: <a href="mailto:hello@nikitacano.com" target="_blank">hello@nikitacano.com</a> or via <a href="https://www.instagram.com/nikita.cano/" target="_blank">Instagram</a>
      </footer>
    </div>
  )
}

export default Layout
